
body {
	color: #121212;
	background: white;
	margin: 0;
	font-family: 'Space Grotesk', sans-serif;
	overflow-y:scroll;
	
}

.page-margin {
    margin: 20px;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: center;
}

.row {
	display: flex;
	align-items: center;
}

.flex-center {
	justify-content: center;
}

.flex-around {
	justify-content: space-around;
}

.flex-between {
	justify-content: space-between;
}

.flex-end {
	justify-content: flex-end;
}

.flex-start {
	justify-content: flex-start;
}

.FtClass {
	color: #245190;
	font-size: 0.5em;
	letter-spacing: 1px;
}

.yellowColor {
	color: #ffff77
}

.tabHeaderCl1:not(:last-child) {
	border-right: 1px dotted rgba(255, 255, 255, 0.4) !important;
}

.tabCellCl1:not(:last-child) {
	border-right: 1px dotted rgba(0, 0, 0, 0.2) !important;
}

button {
	font-size: 1.0em !important;
	margin-bottom: 15px !important;
	margin-top: 10px !important;
}